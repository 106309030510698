var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.replyFormSwitch)?_c('div',{staticClass:"reply-form-container"},[_c('van-overlay',{attrs:{"show":_vm.replyFormSwitch,"lock-scroll":false}},[_c('div',{staticClass:"reply-form__inner"},[(false)?_c('div',{staticClass:"reply-form-title"},[(_vm.exInfo && Number(_vm.exInfo.parentid) === 0)?[_vm._v(" 回应（"+_vm._s(_vm.showText)+"） ")]:(_vm.exInfo && Number(_vm.exInfo.parentid) > 0)?[_vm._v(" 回复"+_vm._s(_vm.exInfo.parentname)+"（"+_vm._s(_vm.showText)+"） ")]:_vm._e()],2):_vm._e(),_c('van-form',{staticClass:"reply-form-main",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form__item"},[(_vm.isCustomTag)?_c('richtext-tag',{attrs:{"placeholder":_vm.theme === 'feedback'
            ? '提出意见'
            : Number(_vm.exInfo.parentid) > 0
              ? `回复${_vm.exInfo.parentname}（${_vm.showText}）`
              : `回应（${_vm.showText}）`,"maxlength":500,"relationship":_vm.exInfo.friendsUpdate},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_c('van-field',{staticClass:"textarea",attrs:{"type":"textarea","maxlength":500,"show-word-limit":true,"placeholder":_vm.theme === 'feedback'
              ? '提出意见'
              : Number(_vm.exInfo.parentid) > 0
                ? `回复${_vm.exInfo.parentname}（${_vm.showText}）`
                : `回应（${_vm.showText}）`},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('div',{staticClass:"form__item"},[_c('van-field',{staticClass:"custom-img-input",scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('wx-upload',{attrs:{"prefix":_vm.prefix,"limit":3},on:{"onChange":_vm.onUploadChange}})]},proxy:true}],null,false,2442805291)})],1),_c('div',{staticClass:"form__bt-container"},[_c('van-button',{staticClass:"form-cancel-bt",attrs:{"native-type":"button"},on:{"click":_vm.onCancel}},[_vm._v("取消")]),_c('van-button',{staticClass:"form-submit-bt",attrs:{"loading":_vm.requesting}},[_c('strong',[_vm._v("确认")])])],1)])],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }