<template>
  <div class="timeline-item-container" :class="[themeClass]" v-if="postInfo">
    <div class="item__header" @click.stop="userJump(postInfo.author ? postInfo.author.uid : null)">
      <img
        :src="postInfo.author ? postInfo.author.headimgurl : dict.logoutDefault['headimgurl']"
        alt=""
      />
    </div>
    <div class="item__main-body">
      <div class="main-body__inner">
        <div class="item__username">
          <a
            href="javascript:void(0)"
            class="item__link"
            @click.stop="userJump(postInfo.author ? postInfo.author.uid : null)"
            ><strong>{{
              (postInfo.author ? postInfo.author.nickname : null)
                | comment(postInfo.author ? postInfo.author.uid : null)
            }}</strong></a
          >
        </div>
        <div class="item__content">
          <div class="p">
            <pre v-html="postInfo.info.content"></pre>
          </div>
          <div class="content__imgs-container" v-if="postInfo.info.imgs.length > 0">
            <div
              class="img__item"
              v-for="(img, index) in postInfo.info.imgs"
              :key="index"
              :data-src="img | parseImage"
              :data-list="postInfo.info.imgs | parseImage"
              v-preview
            >
              <img :src="img | parseImage" alt="" />
            </div>
          </div>
        </div>
        <div class="item__other-info">
          <div class="info__publish other-info__child">
            <p>
              {{
                (theme === "timeline" ? postInfo.publish_at : postInfo.created_at)
                  | parseTime("{y}.{m}.{d}")
              }}
            </p>
            <p v-if="postInfo.status === 3">已被管理员隐藏</p>
          </div>
          <div class="info__delete-bt other-info__child">
            <template v-if="theme === 'feedback'">
              <p @click.stop="onPostDelete" :data-postid="postInfo.id">删除</p>
            </template>
            <template v-else>
              <p
                v-if="
                  user &&
                  isShowDelete(postInfo.author_uid || (postInfo.author ? postInfo.author.uid : 0))
                "
                @click.stop="onPostDelete"
                :data-postid="postInfo.id"
              >
                删除
              </p>
              <p
                v-if="
                  hiddenPost &&
                  !(
                    user &&
                    isShowDelete(postInfo.author_uid || (postInfo.author ? postInfo.author.uid : 0))
                  )
                "
                @click.stop="onPostHide"
                :data-postid="postInfo.id"
              >
                隐藏
              </p>
            </template>
          </div>
          <div
            class="info__edit-bt other-info__child --pos-right"
            @click.stop="onPostReply"
            :data-postid="postInfo.id"
            :data-author-uid="postInfo.author ? postInfo.author.uid : null"
          >
            <!-- 小组，话题分享里会显示hint -->
            <div v-if="isShowLatest && postInfo._unlock" class="group__unlock-more">
              <p>打卡解锁更多</p>
            </div>
            <svg-icon icon-class="md-message"></svg-icon>
          </div>
        </div>
        <div class="item__replies-list-container">
          <div class="replies-list__inner">
            <div
              class="replies-list__header"
              :class="theme"
              v-if="
                theme !== 'topic' &&
                theme !== 'feedback' &&
                !(theme === 'timeline' && renderType === 'group')
              "
            >
              <i class="header__icon"></i>
              <div class="header__relations-user">
                <template v-if="theme === 'group' && postInfo.lockedman">
                  <a
                    href="javascript:void(0)"
                    class="user__name"
                    v-for="(man, index) in postInfo.lockedman"
                    :key="man.id"
                    @click.stop="userJump(man.info.uid)"
                  >
                    <strong>{{
                      (man.info ? man.info.nickname : "") | comment(man.info.uid)
                    }}</strong>
                    <span v-if="index + 1 < postInfo.lockedman.length">、</span>
                  </a>
                </template>
                <template v-else-if="theme === 'timeline' && postInfo.relationship">
                  <a
                    href="javascript:void(0)"
                    class="user__name"
                    v-for="(man, index) in postInfo.relationship"
                    :key="man.id"
                    @click.stop="userJump(man.maininfo ? man.maininfo.uid : 0)"
                  >
                    <strong>{{
                      (man.maininfo ? man.maininfo.nickname : "")
                        | comment(man.maininfo ? man.maininfo.uid : null)
                    }}</strong>
                    <span v-if="index + 1 < postInfo.relationship.length">、</span>
                  </a>
                </template>
              </div>
            </div>
            <div class="replies-list__main-body" v-if="showReplies.length > 0">
              <template v-if="theme !== 'feedback'">
                <div
                  class="reply__item"
                  v-for="reply in showReplies"
                  :key="reply.id"
                  @click.stop="onPostReply"
                  :data-reply-id="reply.id"
                  :data-postid="postInfo.id"
                  :data-parentid="reply.id"
                  :data-parentname="reply.author ? reply.author.nickname : ''"
                  :data-author-uid="reply.author ? reply.author.uid : 0"
                >
                  <div class="item__text-body">
                    <template v-if="Number(reply.parent_id) === 0">
                      <div class="item__left">
                        <a
                          href="javascript:void(0)"
                          class="left__username"
                          @click.stop="userJump(reply.author ? reply.author.uid : 0)"
                          >{{
                            (reply.author ? reply.author.nickname : null)
                              | comment(reply.author ? reply.author.uid : null)
                          }}：</a
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div class="item__left">
                        <a
                          href="javascript:void(0)"
                          class="left__username"
                          @click.stop="userJump(reply.author ? reply.author.uid : 0)"
                          >{{
                            (reply.author ? reply.author.nickname : null)
                              | comment(reply.author ? reply.author.uid : null)
                          }}</a
                        >
                        <span> 回复 </span>
                        <a
                          href="javascript:void(0)"
                          class="left__username"
                          v-if="reply.parent"
                          @click.stop="userJump(reply.parent.author ? reply.parent.author.uid : 0)"
                          >{{
                            (reply.parent.author ? reply.parent.author.nickname : null)
                              | comment(reply.parent.author ? reply.parent.author.uid : null)
                          }}：</a
                        >
                      </div>
                    </template>
                    <div class="item__right">
                      <strong>
                        <pre v-html="transformRegReply(reply.content)"></pre>
                      </strong>
                      <span>{{ reply.created_at | parseTime("{y}.{m}.{d}") }}</span>
                    </div>
                  </div>
                  <div class="item__img-body" v-if="reply.imgs.length > 0">
                    <div class="img-body__inner">
                      <div
                        class="img__item"
                        v-for="(img, index) in reply.imgs"
                        :key="index"
                        :data-src="img | parseImage"
                        :data-list="reply.imgs | parseImage"
                        v-preview
                      >
                        <div class="img-item__inner">
                          <img :src="img | parseImage" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="reply__item"
                  v-for="reply in showReplies"
                  :key="reply.id"
                  @click.stop="onPostReply"
                  :data-reply-id="reply.id"
                  :data-postid="postInfo.id"
                  :data-parentid="reply.id"
                  :data-parentname="
                    reply.author && user
                      ? Number(reply.author.uid) !== Number(user.uid)
                        ? '时光'
                        : reply.author.nickname
                      : '时光'
                  "
                  :data-author-uid="
                    reply.author && user
                      ? Number(reply.author.uid) !== Number(user.uid)
                        ? 0
                        : reply.author.uid
                      : 0
                  "
                >
                  <div class="item__text-body">
                    <div class="item__left">
                      <a href="javascript:void(0)" class="left__username"
                        >{{
                          reply.author && user
                            ? Number(reply.author.uid) !== Number(user.uid)
                              ? "时光"
                              : reply.author.nickname
                            : "时光"
                        }}：</a
                      >
                    </div>
                    <div class="item__right">
                      <strong>
                        <pre v-html="transformRegReply(reply.content)"></pre>
                      </strong>
                      <span>{{ reply.created_at | parseTime("{y}.{m}.{d}") }}</span>
                    </div>
                  </div>
                  <div class="item__img-body" v-if="reply.imgs && reply.imgs.length > 0">
                    <div class="img-body__inner">
                      <div
                        class="img__item"
                        v-for="(img, index) in reply.imgs"
                        :key="index"
                        :data-src="img | parseImage"
                        :data-list="reply.imgs | parseImage"
                        v-preview
                      >
                        <div class="img-item__inner">
                          <img :src="img | parseImage" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { transformRegReply } from '@/filters';
import * as dict from '@/utils/dict';

const delUrl = {
  topic: '/api/user/topic/post/delete',
  timeline: 'api/user/timeline/post/delete',
  group: 'api/group/:groupID/post/delete/:postID?sign=:sign',
  feedback: 'api/user/account/feedbacks/:fid',
};

export default {
  name: 'c__timeline-list-item',
  props: {
    /**
     * theme
     * 默认 timeline
     * [timeline,group,topic,feedback]
     */
    theme: {
      type: String,
      default: 'timeline',
    },
    info: {
      type: Object,
      default: null,
    },
    queue: {
      type: Number,
      default: 0,
    },
    lockAll: {
      type: Boolean,
      default: false,
    },
    callPostDel: {
      type: Function,
      default: null,
    },
    isStranger: {
      type: Boolean,
      default: true,
    },
    deleteUrl: {
      type: String,
      default: '',
    },
    canHiddenPost: {
      type: Boolean,
      default: false,
    },
    isShowLatest: {
      type: Boolean,
      default: true,
    },
    groupMangers: {
      default: () => [],
    },
    renderType: {
      default: '',
    },
  },
  computed: {
    ...mapState(['user', 'friends']),
    themeClass() {
      return `theme-${this.theme}`;
    },
    innerReplyShowLimit() {
      return this.$attrs['reply-limit'] === undefined ? 0 : this.$attrs['reply-limit'];
    },
    showReplies() {
      const { info } = this;
      if (info.reply && info.reply.length > 0) {
        return this.innerReplyShowLimit > 0
          ? info.reply.slice(0, this.innerReplyShowLimit)
          : info.reply;
      }
      return [];
    },
    postInfo() {
      return this.info;
    },
    hiddenPost() {
      return this.theme === 'group' && this.canHiddenPost;
    },
  },
  data() {
    return {
      dict,
    };
  },
  mounted() {},
  methods: {
    isShowDelete(authorUid) {
      const UID = Number(this.user.uid);
      const isAuthor = Number(authorUid) === UID;
      const isGroupManager = this.renderType === 'group'
        && this.groupMangers.findIndex((item) => Number(item) === UID) >= 0;
      return this.renderType === 'group' ? isAuthor || isGroupManager : isAuthor;
    },
    onPostHide(e) {
      const that = this;
      const obj = e.currentTarget;
      const { postid } = obj.dataset;
      const groupid = this.$route.params.id || this.$route.params.groupid;
      that.$dialog
        .confirm({
          title: '操作提醒',
          message: '是否隐藏此内容',
          confirmButtonText: '隐藏',
          beforeClose(action, done) {
            if (action === 'confirm') {
              that.$request.get(`api/group/${groupid}/post/hide/${postid}`).then(() => {
                done();
              });
            } else {
              done();
            }
          },
        })
        .then(() => {
          that.$emit('postHide', postid);
        })
        .catch(() => {
          console.log('取消');
        });
    },
    onPostDelete(e) {
      const that = this;
      const obj = e.currentTarget;
      const { postid } = obj.dataset;
      let deleteUrl = delUrl[that.theme];
      if (that.renderType === 'group') {
        const { groupID } = that.$route.params;
        deleteUrl = delUrl[this.renderType];
        deleteUrl = deleteUrl.replace(/:postID/, postid);
        deleteUrl = deleteUrl.replace(/:groupID/, groupID);
        deleteUrl = deleteUrl.replace(/:sign/, that.theme);
      }
      if (that.callPostDel) {
        that.$dialog
          .confirm({
            title: '操作提醒',
            message: '是否确认删除此信息',
            confirmButtonText: '删除',
            beforeClose(action, done) {
              if (action === 'confirm') {
                that
                  .callPostDel({
                    postid,
                    deleteUrl,
                  })
                  .then(() => {
                    done();
                  });
              } else {
                done();
              }
            },
          })
          .then(() => {
            that.$emit('postDel', postid);
          })
          .catch(() => {
            console.log('取消');
          });
      }
    },
    onPostReply(e) {
      const that = this;
      const obj = e.currentTarget;
      const targetTagName = e.target.tagName.toLowerCase();
      const { authorUid } = obj.dataset;
      const { uid } = that.user;
      if (that.isStranger) {
        that.$dialog
          .alert({
            title: '消息提示',
            message: '该话题发布者不是您的好友',
            confirmButtonText: '查看发布人',
          })
          .then(() => {
            that.$router.replace({
              path: `/friend/${authorUid}?from=forbidden`,
            });
          });
      } else if (targetTagName !== 'a') {
        // console.log(this.user)
        const { replyId } = obj.dataset;
        if (replyId !== undefined) {
          if (Number(authorUid) === Number(uid)) {
            that.$dialog
              .confirm({
                message: '确定删除吗？',
                beforeClose: (action, done) => {
                  if (action === 'confirm') {
                    const deleteUrl = `api/user/${that.theme}/reply/delete/${replyId}`;
                    that.$request.del(deleteUrl).then((res) => {
                      const rid = Number(res.data.replyId);
                      const tempReply = that.info.reply.filter((reply) => Number(reply.id) !== rid);
                      that.info.reply = tempReply;
                      if (that.$route.params.groupID === undefined && this.theme === 'topic') {
                        window.location.reload();
                      }
                      done();
                    });
                  } else {
                    done();
                  }
                },
              })
              .then((a) => {
                console.log(a);
              })
              .catch((a) => {
                console.log(a);
              });
            return;
          }
        }
        let relatedUsers = null;
        if (this.theme === 'timeline') {
          relatedUsers = this.postInfo.relationship
            .filter((item) => item.maininfo !== null)
            .map((item) => item.maininfo.uid);
        }
        const data = {
          postid: obj.dataset.postid,
          parentid: obj.dataset.parentid ? obj.dataset.parentid : 0,
          parentname: obj.dataset.parentname ? obj.dataset.parentname : '',
          relatedUsers,
        };
        this.$emit('postReply', data);
      }
    },
    onReplyDelete() {},
    transformRegReply(str) {
      return transformRegReply(str);
    },
  },
};
</script>

<style></style>
