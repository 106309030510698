<template>
  <div class="reply-form-container" v-if="replyFormSwitch">
    <van-overlay :show="replyFormSwitch" :lock-scroll="false">
      <div class="reply-form__inner">
        <div class="reply-form-title" v-if="false">
          <template v-if="exInfo && Number(exInfo.parentid) === 0">
            回应（{{ showText }}）
          </template>
          <template v-else-if="exInfo && Number(exInfo.parentid) > 0">
            回复{{ exInfo.parentname }}（{{ showText }}）
          </template>
        </div>
        <van-form class="reply-form-main" @submit="onSubmit">
          <div class="form__item">
            <richtext-tag v-if="isCustomTag" v-model="content" :placeholder="theme === 'feedback'
              ? '提出意见'
              : Number(exInfo.parentid) > 0
                ? `回复${exInfo.parentname}（${showText}）`
                : `回应（${showText}）`
              " :maxlength="500" :relationship="exInfo.friendsUpdate"></richtext-tag>
            <van-field v-else class="textarea" v-model="content" type="textarea" :maxlength="500" :show-word-limit="true"
              :placeholder="theme === 'feedback'
                ? '提出意见'
                : Number(exInfo.parentid) > 0
                  ? `回复${exInfo.parentname}（${showText}）`
                  : `回应（${showText}）`
                "></van-field>
          </div>
          <div class="form__item">
            <van-field class="custom-img-input">
              <template #input>
                <wx-upload :prefix="prefix" :limit="3" @onChange="onUploadChange"></wx-upload>
              </template>
            </van-field>
          </div>
          <div class="form__bt-container">
            <van-button class="form-cancel-bt" native-type="button" @click="onCancel">取消</van-button>
            <van-button class="form-submit-bt" :loading="requesting"><strong>确认</strong></van-button>
          </div>
        </van-form>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import richtextTag from '@/views/common/_rich-text-tag.vue';
import wxUpload from './_upload-bt.vue';

export default {
  name: 'c__reply-form',
  components: {
    wxUpload,
    richtextTag,
  },
  props: {
    postLink: {
      type: String,
      required: true,
    },
    exInfo: {
      type: Object,
      required: true,
      default: null,
    },
    theme: {
      type: String,
      default: 'group',
    },
    isCustomTag: {
      type: Boolean,
      default: false,
    },
    isAuthor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgs: '',
      content: '',
      requesting: false,
      tag4Selected: [],
    };
  },
  computed: {
    ...mapState({
      replyFormSwitch: (state) => state.isShowReplyForm,
    }),
    groupID() {
      return this.$route.params.groupID || 0;
    },
    showText() {
      if (this.theme === 'group') {
        return '组内成员可见';
      }
      if (this.theme === 'timeline') {
        return this.groupID > 0 ? '组内成员可见' : '关联好友可见';
      }
      if (this.theme === 'topic') {
        if (this.groupID > 0) {
          return '组内成员可见';
        } if (!this.exInfo.parentid && this.isAuthor) {
          return '主题内权限好友可见';
        }
        return '只有你和对方可见';
      }
      return '可见';
    },
    prefix() {
      if (this.theme === 'group') {
        return 'group-reply';
      }
      if (this.theme === 'timeline') {
        return 'timeline-reply';
      }
      return 'topic-reply';
    },
  },
  // watch: {
  //   content(val) {
  //     this.formatContent(val);
  //   },
  // },
  methods: {
    formatContent(val) {
      const temp = val;
      const regex = new RegExp('<span[^>]*class="wrapperClickOnJump"[^>]*data-uid="(\\d+)"[^>]*>(.*?)<\\/span>', 'g');
      return temp.replaceAll(regex, (str, uid, matched) => {
        const replaced = `&[u:${uid}]`;
        const nstr = str.replace(matched, replaced);
        return nstr;
      });
    },
    onUploadChange(val) {
      this.imgs = val.length > 0 ? val : '';
    },
    onSubmit() {
      const that = this;
      const postData = that.exInfo;
      let replyAddUsers = [];
      if (this.theme === 'timeline') {
        // todo 优化正则
        // 添加&的情况
        const node = document.createElement('div');
        node.innerHTML = that.content;
        const rUids = node.querySelectorAll('.wrapperClickOnJump');
        if (rUids.length > 0) {
          replyAddUsers = [...rUids].map((item) => Number(item.dataset.uid));
        }
        postData.replyAddUsers = replyAddUsers;
        delete postData.friendsUpdate;
        delete postData.relatedUsers;
      }
      if (Object.keys(postData).indexOf('friendsUpdate')) {
        delete postData.friendsUpdate;
      }
      if (that.content === '' && that.imgs.length === 0) {
        that.$notify({
          type: 'danger',
          message: '评论和图片请填写一样',
        });
      } else if (!that.requesting) {
        // 把&昵称转化为&[u:uid]提交
        const regStr = that.formatContent(that.content);
        that.requesting = true;
        postData.content = regStr;
        postData.imgs = that.imgs;
        that.$request
          .post(that.postLink, postData)
          .then((res) => {
            that.beforeClose();
            that.$emit('afterReply', res);
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    beforeClose() {
      this.imgs = '';
      this.content = '';
      this.requesting = false;
    },
    onCancel() {
      this.beforeClose();
      this.$emit('cancel');
    },
  },
};
</script>

<style></style>
